import React, {useState} from "react";
import {Card, Col, Container, Image, Row} from "react-bootstrap";
import "./mobilestyles.css";
import mobileLogo2 from "../../images/mobile_logo2.png";
import "react-swipeable-list/dist/styles.css";
import DefaultAvatar from "../../images/avatar_default.png";
import {default as Views} from "../tempjob/icons/visningar.svg";
import {default as VerifiedIcon} from "../../images/vikaaria_verified_badge.svg"
import {Link} from "react-router-dom";
import ViewsModal from "../modals/ViewsModal";

const TeacherMobileLayout = (props) => {
    const {
        jobs,
        applicants,
        icon,
        text,
        user,
        avatar,
        displayDates,
        viewsTooltip,
        teachers,
        checkIfApplied,
        choseClass,
        handleOpenChange,
        renderCard,
        language,
        chooseBell,
        lessPadding,
        handleQualifications,
        preschool,
        viewUsers,
        rememberedUsers,
    } = props;
    const imgUrl = `${window.location.origin}/api/shared/image/`;
    const [open, setOpen] = useState("");
    return (
        <div className="mobile-h " /* mb-5 */>
            <div
                className="m-header" /* {scrollY >= 105 ? "m-header-scroll" : "m-header"} */
            >
                {/* <Image fluid src={mobileHeader} alt="header" className="mobile-header-image" /> */}
                <Image
                    fluid
                    src={mobileLogo2}
                    alt="logo"
                    className="mobile-l" /* {scrollY >= 105 ? "mobile-l" : "mobile-l logo-anim"} */
                />
                {avatar && (
                    <Image
                        fluid
                        roundedCircle
                        alt="avatar"
                        className="avatar"
                        src={imgUrl + user.image}
                    />
                )}
                <p className="header-text">
                    {icon && (
                        <Image
                            src={icon}
                            className="mobile-header-svg teacher-svg-size mr-2"
                        />
                    )}
                    {text}
                </p>
            </div>
            {renderCard && (
                <div
                    style={{
                        marginBottom: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                    }}
                    className={lessPadding ? "m-cards-lessPad" : "m-cards"}
                >
                    {jobs.map((job, index) => (
                        <>
                            <ViewsModal
                                viewUsers={viewUsers}
                                job={job}
                                open={open === job._id}
                                setOpen={setOpen}
                                rememberedUsers={rememberedUsers}
                            />

                            <div className="mobile-job-header">
                                <Link className="w-75" to={`/manage/${job.uniqueId}`}>
                                    <div className="job-h-bg">
                                        <div className="arrow-down"></div>
                                        <Row className=" job-m-header">
                      <span
                          style={{
                              fontSize: "1.6em",
                              fontWeight: "700",
                              color: "#555aa1",
                          }}
                      >
                        {displayDates(job)}
                      </span>
                                            <span
                                                className="truncate-reason-text"
                                                style={{
                                                    marginLeft: "8px",
                                                    fontWeight: "600",
                                                    fontSize: "1.4em",
                                                }}
                                            >
                        {user.typeOfUser !== "teacher"
                            ? job.owner !== user.id
                                ? teachers?.map((teach) => {
                                    if (job.owner === teach.id) {
                                        return `(${teach.firstName} ${teach.lastName} - ${job.reason})`;
                                    }
                                    return "";
                                })
                                : typeof job.regarding !== "undefined"
                                    ? `  (${job.regarding} - ${job.reason})`
                                    : `  (${user.firstName} ${user.lastName} - ${job.reason})`
                            : `(${job.reason})`}
                      </span>
                                            <span
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setOpen(job._id);
                                                }}
                                                className="mobile-view-container"
                                            >
                        <img alt="" className="view-svg" src={Views}/>
                        <p className="view-counter">{job.views.length}</p>
                      </span>
                                        </Row>
                                    </div>
                                </Link>
                                <Row>
                                    {job.applicants.length > 0 ? (
                                        applicants.map((user, index) =>
                                            checkIfApplied(job, user.email) ? (
                                                <Col md={12} key={index} className="mb-3 mt-2">
                                                    <Card
                                                        as="a"
                                                        onClick={(event) =>
                                                            handleOpenChange(user, job.uniqueId)
                                                        }
                                                        className={`${
                                                            job.chosen === user.email && "chosen"
                                                        } mobile-card`}
                                                    >
                                                        <Container className="mobile-image-container">
                                                            <Card.Img
                                                                variant="left"
                                                                className="m-image img-size"
                                                                src={
                                                                    user.image
                                                                        ? imgUrl + user.image
                                                                        : DefaultAvatar
                                                                }
                                                            />
                                                        </Container>
                                                        <Col className="my-auto">
                                                            <Card.Body className="no-padding h-100">
                                                                <Card.Title
                                                                    className="mb-0 mt-0"
                                                                    style={{
                                                                        fontWeight: "600",
                                                                        fontSize: "1.6em",
                                                                        color:
                                                                            job.chosen === user.email
                                                                                ? ""
                                                                                : "#2e2f31",
                                                                    }}
                                                                >
                                                                    {user.firstName} {user.lastName}

                                                                    {user?.identityVerified &&
                                                                        <img alt="User verified icon"
                                                                             src={VerifiedIcon}
                                                                             className="verified-icon"/>}
                                                                </Card.Title>
                                                                {/* <Card.Text className="mb-0" style={{ fontSize: "1.4em", color: job.chosen === user.email ? "" : "#707070" }}>
                                                            {user.status}
                                                        </Card.Text> */}
                                                                {handleQualifications(
                                                                    user?.status,
                                                                    job,
                                                                    user,
                                                                    language,
                                                                    true
                                                                )}
                                                            </Card.Body>
                                                        </Col>
                                                        {/* <Container className="mobile-arrow-container-teacher align-middle" id={job.uniqueId} onClick={(event) => handleOpenChange(user, job.uniqueId)}>
                                                    <FontAwesomeIcon size="lg" color={job.chosen === user.email ? "" : "#9192a2"} icon={faChevronRight} />
                                                </Container> */}
                                                        {chooseBell(job, user)}
                                                    </Card>
                                                </Col>
                                            ) : (
                                                ""
                                            )
                                        )
                                    ) : (
                                        <Col md={12} key={index} className="mb-3 mt-2">
                                            <Card className="no-applicants-card">
                                                <Col>
                                                    <Card.Body>
                                                        <Card.Text>{language.noapplicantstext}</Card.Text>
                                                    </Card.Body>
                                                </Col>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TeacherMobileLayout;
