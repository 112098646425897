import React from "react";
import {Card, Col} from "react-bootstrap";
import {extractTime} from "./extractTime";

const MobileCard = (props) => {
    const {
        job,
        index,
        bell,
        openJob,
        dates,
        c_name,
        home,
        chosen,
        inactive,
        grayText,
        language,
    } = props;
    const getLocation = (address) => {
        try {
            const parts = address.split(",");
            const lastPart = parts[parts.length - 1].trim();
            return lastPart || ""
        } catch (error) {
            console.error(error)
            return "";
        }
    }
    return job.school !== "Admin" ? (
        <Col md={12} key={job.uniqueId} className={`mb-4 ${c_name}`}>
            {home && extractTime(job)}
            <Card
                className={`align-middle ${
                    inactive ? "inactive-m-card" : "mobile-card"
                } ${chosen}`}
                as="a"
                id={job.uniqueId}
                onClick={openJob}
            >
                {(chosen === "reg" || typeof chosen === "undefined" || !chosen) && bell}
                <Col
                    className="my-auto"
                    style={{
                        marginLeft:
                            chosen === "reg" || typeof chosen === "undefined" || !chosen
                                ? ""
                                : "1.8rem",
                    }}
                >
                    <Card.Body className={`no-padding h-100 ${chosen}`}>
                        <Card.Title className={`mb-0 cBold truncate-90 t-lg ${chosen}`}>
                            {job.school}
                        </Card.Title>
                        {job?.address && (
                            <Card.Text
                                className={`mb-0 cReg truncate-90 t-md ${
                                    chosen === "chosen" || chosen === "not-chosen" ? "" : "tc-gray"
                                } `}>
                                {getLocation(job.address)}
                            </Card.Text>
                        )}
                        <Card.Text
                            style={{color: grayText ? "#9192a2" : ""}}
                            className={`mb-0 mt-0 truncate-90 cReg t-md ${
                                chosen === "reg" ? "date-sub-color" : ""
                            }`}
                        >
                            {dates} | {job.startTime}-{job.endTime}
                        </Card.Text>
                        <Card.Text
                            style={{color: grayText ? "#9192a2" : ""}}
                            className={`cReg mt-0 truncate-90 t-md truncate ${
                                chosen === "reg" ? "date-sub-color tc-gray" : ""
                            }`}
                        >
                            {
                                language.fi
                                    ? job?.typeOfTemp?.name_fi
                                    : job?.typeOfTemp?.name_swe
                            }
                        </Card.Text>
                    </Card.Body>
                </Col>
                {/* <Container className="mobile-arrow-container align-middle" id={job.uniqueId} onClick={openJob}>
                    <FontAwesomeIcon size="lg" color={chosen === "chosen" || chosen === "not-chosen" ? "#fff" : "#9192a2"} icon={faChevronRight} />
                </Container> */}
            </Card>
        </Col>
    ) : job.fromAdminType === "Update" ? (
        <Col md={12} key={index} className={`mb-4 ${c_name}`}>
            {home && extractTime(job)}
            <Card
                className={`align-middle ${
                    inactive ? "inactive-m-card" : "mobile-card"
                } ${chosen}`}
                as="a"
                id={job.uniqueId}
                onClick={openJob}
            >
                {(chosen === "reg" || typeof chosen === "undefined" || !chosen) && bell}
                <Col
                    className="my-auto"
                    style={{
                        marginLeft:
                            chosen === "reg" || typeof chosen === "undefined" || !chosen
                                ? ""
                                : "1.8rem",
                    }}
                >
                    <Card.Body className={`no-padding h-100 ${chosen}`}>
                        <Card.Title className={`mb-0 cBold t-lg ${chosen}`}>
                            Vikaaria
                        </Card.Title>
                        <Card.Text
                            style={{color: grayText ? "#9192a2" : ""}}
                            className={`mb-0 mt-0 cReg t-md ${
                                chosen === "reg" ? "date-sub-color" : ""
                            }`}
                        >
                            {dates}
                        </Card.Text>
                        <Card.Text
                            style={{color: grayText ? "#9192a2" : ""}}
                            className={`cReg mt-0 t-md truncate ${
                                chosen === "reg" ? "date-sub-color tc-gray" : ""
                            }`}
                        >
                            UPDATE
                        </Card.Text>
                    </Card.Body>
                </Col>
            </Card>
        </Col>
    ) : job.fromAdminType === "Update" ? (
        <Col md={12} key={index} className={`mb-4 ${c_name}`}>
            {home && extractTime(job)}
            <Card
                className={`align-middle ${
                    inactive ? "inactive-m-card" : "mobile-card"
                } ${chosen}`}
                as="a"
                id={job.uniqueId}
                onClick={openJob}
            >
                {(chosen === "reg" || typeof chosen === "undefined" || !chosen) && bell}
                <Col
                    className="my-auto"
                    style={{
                        marginLeft:
                            chosen === "reg" || typeof chosen === "undefined" || !chosen
                                ? ""
                                : "1.8rem",
                    }}
                >
                    <Card.Body className={`no-padding h-100 ${chosen}`}>
                        <Card.Title className={`mb-0 cBold t-lg ${chosen}`}>
                            Vikaaria
                        </Card.Title>
                        <Card.Text
                            style={{color: grayText ? "#9192a2" : ""}}
                            className={`mb-0 mt-0 cReg t-md ${
                                chosen === "reg" ? "date-sub-color" : ""
                            }`}
                        >
                            {dates}
                        </Card.Text>
                        <Card.Text
                            style={{color: grayText ? "#9192a2" : ""}}
                            className={`cReg mt-0 t-md truncate ${
                                chosen === "reg" ? "date-sub-color tc-gray" : ""
                            }`}
                        >
                            UPDATE
                        </Card.Text>
                    </Card.Body>
                </Col>
            </Card>
        </Col>
    ) : (
        <Col md={12} key={index} className={`mb-4 ${c_name}`}>
            {home && extractTime(job)}
            <Card
                className={`align-middle ${
                    inactive ? "inactive-m-card" : "mobile-card height-override"
                } ${chosen}`}
                as="a"
                id={job.uniqueId}
                onClick={openJob}
            >
                {(chosen === "reg" || typeof chosen === "undefined" || !chosen) && bell}
                <Col
                    className="my-auto"
                    style={{
                        marginLeft:
                            chosen === "reg" || typeof chosen === "undefined" || !chosen
                                ? ""
                                : "1.8rem",
                    }}
                >
                    <Card.Body className={`no-padding h-100 ${chosen}`}>
                        <Card.Title className={`mb-0 cBold t-lg ${chosen}`}>
                            Vikaaria
                        </Card.Title>
                        <Card.Text
                            style={{color: grayText ? "#9192a2" : ""}}
                            className={`mb-0 mt-0 cReg t-md ${
                                chosen === "reg" ? "date-sub-color" : ""
                            }`}
                        >
                            {dates}
                        </Card.Text>
                        <Card.Text
                            style={{color: grayText ? "#9192a2" : ""}}
                            className={`cReg mt-0 t-md truncate ${
                                chosen === "reg" ? "date-sub-color tc-gray" : ""
                            }`}
                        >
                            Vikaaria tiedottaa/informerar
                        </Card.Text>
                    </Card.Body>
                </Col>
            </Card>
        </Col>
    );
};
export default MobileCard;
