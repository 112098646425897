import React from "react";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import CustomNavbar from "../navigation/customNavbar";
import "../../styles/anslagstavlan.css";
import "../../styles/body-verticalCenter.css";
import {logoutTeacher} from "../../actions/authActions";
import {Card, Col, Container, OverlayTrigger, Row, Tooltip,} from "react-bootstrap";
import Sort from "../func-components/sort";
import Spinner from "../func-components/spinner";
import {io} from "socket.io-client";
import Modal from "react-modal";
import {default as Views} from "./icons/visningar.svg";
import {default as Location} from "./icons/location.svg";
import {default as Mail} from "./icons/mail.svg";
import {default as Phone} from "./icons/phone.svg";
import {default as EditIcon} from "../navigation/icons/edit_post.svg";
import {default as Applied} from "../navigation/icons/calendar-check-regular.svg";
import {default as SeenIcon} from "../users-only/icons/sedd.svg";
import {default as CheckSolid} from "../users-only/icons/check-solid.svg";
import {default as BookMarked} from "../../images/Bookmark_white.svg";
import {default as VerifiedIcon} from "../../images/vikaaria_verified_badge.svg";

import TeacherMobileLayout from "../mobile/teacherMobileLayout";
import {default as BellImportant} from "../users-only/icons/notification_important.svg";
import DefaultAvatar from "../../images/avatar_default.png";
import sv from "../../texts/sv.json";
import fi from "../../texts/fi.json";

//jobs/overview
class Anslagstavlan extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            tempJobs: [],
            applicants: [],
            errors: {},
            teachers: [],
            applicant: {},
            jobId: "",
            listSort: true,
            isLoading: true,
            isOpen: false,
            sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
            mobile: false,
            language: {},
            preschool: false,
            viewUsers: [],
        };
    }

    componentDidMount() {
        Modal.setAppElement("body");
        window.scrollTo(0, 0);
        if (this.props.auth.teacher.typeOfUser === "user") {
            this.props.history.push("/jobs/all");
        }
        const {teacher} = this.props.auth;

        if (window.innerWidth < 768) {
            this.setState({listSort: false});
        }
        this.getLang(this.props.auth.teacher);

        if (teacher.typeOfUser !== "user") {
            var OneSignal = window.OneSignal || [];
            OneSignal.push(function () {
                OneSignal.setExternalUserId(teacher.email);
                OneSignal.sendTag("user_type", teacher.typeOfUser);
                OneSignal.sendTag("email", teacher.email);
            });
        }

        const handler = () => {
            let match = window.matchMedia("(max-width: 600px)").matches;
            if (match) {
                this.setState({listSort: false, mobile: true});
            } else {
                this.setState({mobile: false});
            }
        };
        handler();
        window.addEventListener("resize", handler);

        if (teacher.typeOfUser === "teacher") {
            this.getTeacherData();
        }

        if (teacher.typeOfUser === "principal") {
            this.getPrinData();
        }

        var socket = io();

        socket.on("updateJobs", () => {
            if (teacher.typeOfUser === "principal") {
                this.getPrinData();
            } else if (teacher.typeOfUser === "teacher") {
                this.getTeacherData();
            }
        });
    }

    getPrinData = async () => {
        const getTempInfoData = {
            owner: this.props.auth.teacher.id,
            email: this.props.auth.teacher.email,
        };
        await axios.post("/api/principals/temps/v2", getTempInfoData).then((res) => {
            this.setState(
                {
                    tempJobs: res.data.jobs.sort(
                        (a, b) => new Date(a.startDate) - new Date(b.startDate)
                    ),
                    preschool: res.data.preschool,
                    viewUsers: res.data.viewUsers,
                },
                async () => {
                    const data = {
                        email: this.props.auth.teacher.email,
                        school: this.props.auth.teacher.school,
                    };
                    await axios
                        .post("/api/shared/get/school", data)
                        .then(async (res) => {
                            const data2 = {
                                email: this.props.auth.teacher.email,
                                school: this.props.auth.teacher.school,
                                region: res.data.region[0],
                            };
                            await axios
                                .post("/api/teachers/showapplicants", data2)
                                .then((res) => {
                                    this.setState({applicants: res.data.sort()}, () => {
                                        setTimeout(() => {
                                            this.handleScrollPos();
                                        }, 2);
                                    });
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                        .catch((err) => console.log(err));
                }
            );
        });
        const data2 = {
            email: this.props.auth.teacher.email,
        };
        await axios
            .post("/api/principals/teachers/getall", data2)
            .then((res) => {
                this.setState({teachers: res.data.teachers, isLoading: false});
            })
            .catch((err) => console.log(err));
    };

    getTeacherData = async () => {
        const getTempInfoData = {
            owner: this.props.auth.teacher.id,
            email: this.props.auth.teacher.email,
        };
        await axios
            .post("/api/teachers/gettemp/v2", getTempInfoData)
            .then((res) => {
                this.setState(
                    {
                        tempJobs: res.data.jobs.sort(
                            (a, b) => new Date(a.startDate) - new Date(b.startDate)
                        ),
                        viewUsers: res.data.viewUsers,
                    },
                    async () => {
                        const data = {
                            email: this.props.auth.teacher.email,
                            school: this.props.auth.teacher.school,
                        };
                        await axios
                            .post("/api/shared/get/school", data)
                            .then(async (res) => {
                                const data2 = {
                                    email: this.props.auth.teacher.email,
                                    school: this.props.auth.teacher.school,
                                    region: res.data.region[0],
                                };
                                await axios
                                    .post("/api/teachers/showapplicants", data2)
                                    .then((res) => {
                                        this.setState({applicants: res.data.sort()});
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            })
                            .catch((err) => console.log(err));
                    }
                );
                this.setState({isLoading: false});
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleScrollPos = () => {
        const scrollPos = sessionStorage.getItem("scrollPosition");
        if (scrollPos) {
            window.scrollTo(0, scrollPos);
            sessionStorage.removeItem("scrollPosition");
        }
    };

    getLang = (user) => {
        if (user.siteLang === 2) {
            this.setState({language: sv});
        } else if (user.siteLang === 1) {
            this.setState({language: fi});
        } else {
            this.setState({language: fi});
        }
    };

    handleOpenChange = (user, jobId) => {
        sessionStorage.setItem("scrollPosition", window.scrollY);

        this.props.history.push({
            pathname: `/profile/${user.email}`,
            state: {jobId: jobId},
        });
    };

    handleSort = (e) => {
        switch (e.target.id) {
            case "alfabetisk":
                var sorted = this.state.applicants.sort((a, b) =>
                    a.firstName.localeCompare(b.firstName)
                );
                this.setState({applicants: sorted});
                break;
            case "ålder":
                var sorted = this.state.applicants.sort((a, b) => a.age - b.age);
                this.setState({applicants: sorted});
                break;
            case "teacher":
                var sorted = this.state.applicants.sort((a) => {
                    if (a.status === "Lärare" || a.status === "Opettaja") return -1;
                    else return 1;
                });
                this.setState({aplicants: sorted});
                break;
            case "student":
                var sorted = this.state.applicants.sort((a) => {
                    if (a.status === "Studerande" || a.status === "Opiskelija") return -1;
                    else return 1;
                });
                this.setState({aplicants: sorted});
                break;
            case "other":
                var sorted = this.state.applicants.sort((a) => {
                    if (a.status === "Annat" || a.status === "Muu") return -1;
                    else return 1;
                });
                this.setState({aplicants: sorted});
                break;
            case "anmälning":
                var sorted = this.state.applicants.reverse();
                this.setState({aplicants: sorted});
                break;
            default:
                break;
        }
    };

    showSidebar = () => {
        const {sidebar} = this.state;
        if (sidebar) {
            this.setState({sidebar: false});
        } else if (!sidebar) {
            this.setState({sidebar: true});
        }
    };

    openJob = (e) => {
        this.props.history.push(`/specific/${e.currentTarget.id}`);
    };

    mobileBell = (job) => {
        const {teacher} = this.props.auth;
        if (
            !job?.views?.includes(teacher.email) ||
            typeof job?.views === "undefined"
        ) {
            return (
                <div className="mobile-bell-container">
                    <img alt="" src={BellImportant} className="mobile-bell"/>
                </div>
            );
        }
    };

    fullName = (teacher) => {
        return teacher.firstName + " " + teacher.lastName;
    };

    choseClass = (job, user) => {
        if (job.chosen === user.email) {
            return true;
        }
        return false;
    };

    listOrBlock = (e) => {
        if (e.currentTarget.id === "block") {
            this.setState({listSort: false});
        }
        if (e.currentTarget.id === "list") {
            this.setState({listSort: true});
        }
    };

    onLogoutClick = () => {
        this.props.logoutTeacher();
    };

    checkIfApplied = (job, email) => {
        var isApplied = false;
        job.applicants.forEach((applicant) => {
            if (applicant === email) {
                isApplied = true;
            }
        });
        return isApplied;
    };

    removeDot = (date) => {
        if (date[date.length - 1] === ".") {
            date = date.slice(0, -1);
            return date;
        }
        return date;
    };

    displayDates = (job) => {
        var options = {day: "numeric", month: "numeric"};
        if (
            new Date(job.startDate).toDateString() ===
            new Date(job.endDate).toDateString()
        ) {
            return new Date(job.startDate).toLocaleDateString("fi-FI");
        }
        return (
            this.removeDot(
                new Date(job.startDate).toLocaleDateString("fi-FI", options)
            ) +
            " - " +
            new Date(job.endDate).toLocaleDateString("fi-FI")
        );
    };

    displayMobileDates = (job) => {
        var options = {day: "numeric", month: "numeric"};
        if (
            new Date(job.startDate).toDateString() ===
            new Date(job.endDate).toDateString()
        ) {
            return new Date(job.startDate).toLocaleDateString("fi-FI", options);
        }
        return (
            this.removeDot(
                new Date(job.startDate).toLocaleDateString("fi-FI", options)
            ) +
            " - " +
            new Date(job.endDate).toLocaleDateString("fi-FI", options)
        );
    };

    viewsTooltip = (props) => {
        return (
            <Tooltip id="views-tooltip" {...props}>
                <ul>{props.id}</ul>
            </Tooltip>
        );
    };

    chooseBell = (job, user, block) => {
        const {teacher} = this.props.auth;
        const userIsRemembered = () => {
            if (!teacher?.rememberedUsers) {
                return false;
            }
            return teacher?.rememberedUsers?.includes(user.email);
        };
        const isRemembered = userIsRemembered();
        const bookMark = isRemembered ? (
            <img
                alt="bookmarked"
                src={BookMarked}
                className="mobile-check bookmark-icon"
            />
        ) : null;

        const bellStyle = isRemembered ? "default-icon" : "no-bookmark";

        if (job.chosen === user.email) {
            return (
                <div
                    className={
                        block ? "block-bell-container bgGreen" : "mobile-chosen-container"
                    }
                >
                    <div className="icon-wrapper">
                        {bookMark}
                        <img
                            alt=""
                            src={CheckSolid}
                            className={`mobile-check ${bellStyle}`}
                        />
                    </div>
                </div>
            );
        }
        if (
            job.viewedusers.includes(user.email) ||
            typeof job.viewedusers === "undefined"
        ) {
            return (
                <div
                    className={
                        block ? "block-bell-container bgPurple" : "mobile-seen-container"
                    }
                >
                    <div className="icon-wrapper">
                        {bookMark}
                        <img
                            alt=""
                            src={SeenIcon}
                            className={`mobile-bell-seen ${bellStyle}`}
                        />
                    </div>
                </div>
            );
        } else if (!job.viewedusers.includes(user.email)) {
            return (
                <div
                    className={
                        block ? "block-bell-container bgYellow" : "mobile-bell-container"
                    }
                >
                    <div className="icon-wrapper">
                        {bookMark}
                        <img
                            alt=""
                            src={BellImportant}
                            className={`mobile-bell ${bellStyle}`}
                        />
                    </div>
                </div>
            );
        }
    };

    handleQualifications = (status, job, user, language, mobile) => {
        switch (status) {
            case "Studerande":
            case "Opiskelija":
                return (
                    <Card.Text
                        className={`mb-0 truncate-90`}
                        style={{
                            fontSize: "1.4em",
                            color: job.chosen === user.email ? "" : "#707070",
                        }}
                    >
                        {user?.comingqualifications.length > 0
                            ? user.comingqualifications.map((quali, index) =>
                                /* if language is finnish check if index is 0, if language is not finnish its swedish, so check if index is 0 */
                                index === 0
                                    ? (language.fi ? "opisk " : "stud ") +
                                    (index ? " | " : "") +
                                    (language.fi ? quali.name_fi : quali.name_swe)
                                    : ""
                            )
                            : status}
                    </Card.Text>
                );
                break;
            case "Lärare":
            case "Opettaja":
                return (
                    <Card.Text
                        className={`mb-0 truncate-90 ${mobile && "truncate-small"}`}
                        style={{
                            fontSize: "1.4em",
                            color: job.chosen === user.email ? "" : "#707070",
                        }}
                    >
                        {user?.qualifications.length > 0
                            ? user.qualifications.map(
                                (quali, index) =>
                                    (index ? " | " : "") +
                                    (language.fi ? quali.name_fi : quali.name_swe)
                            )
                            : status}
                    </Card.Text>
                );
            default:
                return (
                    <Card.Text
                        className={`mb-0 truncate-90 ${mobile && "truncate-small"}`}
                        style={{
                            fontSize: "1.4em",
                            color: job.chosen === user.email ? "" : "#707070",
                        }}
                    >
                        {user?.qualifications && user?.qualifications.length > 0
                            ? user.qualifications.map(
                                (quali, index) =>
                                    (index ? " | " : "") +
                                    (language.fi ? quali.name_fi : quali.name_swe)
                            )
                            : status || user?.school}
                    </Card.Text>
                );
        }
    };

    langStatus = (user) => {
        const status = user.status;
        const loggedInUser = this.props.auth.teacher;
        if (loggedInUser.siteLang === 1) {
            if (status === "Studerande") {
                return "Opiskelijat";
            } else if (status === "Lärare") {
                return "Opettajat";
            } else {
                return "Muut";
            }
        } else {
            return status;
        }
    };

    getNameFromEmail = (email) => {
        let user = this.state?.viewUsers?.find((e) => e?.email === email);
        return user ? `${user?.firstName} ${user?.lastName}` : "";
    };

    isAvailable = (user, job) => {
        return !user?.unavailableJobs?.includes(job?.uniqueId);
    };

    render() {
        const {
            tempJobs,
            applicants,
            teachers,
            listSort,
            isLoading,
            sidebar,
            applicantCounter,
            tempCounter,
            mobile,
            language,
            preschool,
        } = this.state;
        const {teacher} = this.props.auth;
        const imgUrl = `${window.location.origin}/api/shared/image/`;
        var options = {day: "numeric", month: "numeric"};
        return (
            <div className="temp-body">
                <CustomNavbar
                    userType={teacher.typeOfUser}
                    sidebar={sidebar}
                    showSidebar={this.showSidebar}
                    page={language.nav_bulletin}
                    user={teacher}
                    logoutTeacher={this.onLogoutClick}
                    isAuth={this.props.auth.isAuthenticated}
                />
                {!mobile && (
                    <>
                        <Sort
                            header={language.recapp}
                            listOrBlock={this.listOrBlock}
                            handleSort={this.handleSort}
                            userType={teacher.typeOfUser}
                            marginLeft={sidebar ? "215px" : "16px"}
                            school={teacher.school}
                        />
                        <hr
                            style={{marginTop: "60px", position: "fixed", zIndex: "9"}}
                            className="custom-hr"
                        ></hr>
                    </>
                )}
                <div className={sidebar ? "main push-right" : "main"}>
                    <Container
                        fluid
                        style={{paddingLeft: "0", paddingRight: "0", minHeight: "100%"}}
                    >
                        {!isLoading ? (
                            !mobile ? (
                                tempJobs.map((job, index) => (
                                    <Container key={index} fluid className="card-margin-bottom">
                    <span
                        style={{
                            fontSize: "1.8em",
                            fontWeight: "600",
                            color: "#000000c4",
                        }}
                    >
                      {preschool && job.school}
                    </span>
                                        {listSort ? (
                                            <>
                                                <Row className="mt-3 job-header">
                                                    <a
                                                        style={{textDecoration: "none"}}
                                                        href={`/manage/${job.uniqueId}`}
                                                    >
                            <span
                                style={{
                                    fontSize: "1.8em",
                                    fontWeight: "600",
                                    color: "#555aa1",
                                }}
                            >
                              {this.displayDates(job)}
                            </span>
                                                        <span
                                                            style={{
                                                                marginLeft: "8px",
                                                                fontWeight: "500",
                                                                fontSize: "1.6em",
                                                                color: "#555aa1",
                                                            }}
                                                        >
                              {teacher.typeOfUser !== "teacher"
                                  ? job.owner !== teacher.id
                                      ? teachers?.map((teach) => {
                                          if (job.owner === teach._id) {
                                              return `(${teach.firstName} ${teach.lastName} - ${job.reason})`;
                                          }
                                          return "";
                                      })
                                      : typeof job.regarding !== "undefined" &&
                                      job.regarding !== ""
                                          ? `  (${job.regarding} - ${job.reason})`
                                          : `  (${teacher.firstName} ${teacher.lastName} - ${job.reason})`
                                  : `(${job.reason})`}
                            </span>
                                                    </a>
                                                    <span className="view-container">
                            <OverlayTrigger
                                rootClose={true}
                                rootCloseEvent="click"
                                placement="auto"
                                trigger={["focus", "click"]}
                                delay={{show: 250, hide: 250}}
                                overlay={(props) => (
                                    <Tooltip id="views-tooltip" {...props}>
                                        <div
                                            style={{
                                                maxHeight: "60vh",
                                                overflowY: "auto",
                                                width: "200px",
                                            }}
                                        >
                                            <ul className="list-group">
                                                {job?.views?.map((view) => {
                                                    return (
                                                        <li
                                                            key={view}
                                                            className="text-left overflow-auto"
                                                            style={{listStyle: "none"}}
                                                        >
                                                            {this.getNameFromEmail(view)}
                                                            {teacher?.rememberedUsers?.includes(
                                                                view
                                                            ) ? (
                                                                <img
                                                                    alt="bookmarked icon"
                                                                    src={BookMarked}
                                                                    width={12}
                                                                    className="ml-2"
                                                                />
                                                            ) : null}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </Tooltip>
                                )}
                            >
                              <img
                                  alt=""
                                  className="desktop-view-svg pe-cursor"
                                  src={Views}
                              />
                            </OverlayTrigger>
                            <p className="view-counter">{job.views.length}</p>
                          </span>
                                                    <Link
                                                        to={{
                                                            pathname: `/manage/${job.uniqueId}`,
                                                            state: {
                                                                fromEditIcon: true,
                                                            },
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            className="edit-svg mr-4"
                                                            style={{marginLeft: "3.4em"}}
                                                            src={EditIcon}
                                                        />
                                                    </Link>
                                                </Row>
                                                <Row>
                                                    {applicants.map((user, index) =>
                                                        this.checkIfApplied(job, user.email) ? (
                                                            <Col md={12} key={index} className="mb-3 mt-2">
                                                                {/* <StarRating /> */}
                                                                <Card
                                                                    onClick={(event) => {
                                                                        if (this.isAvailable(user, job)) {
                                                                            this.handleOpenChange(user, job.uniqueId);
                                                                        }
                                                                    }}
                                                                    className={`${
                                                                        job.chosen === user.email && "chosen"
                                                                    }  ${
                                                                        this.isAvailable(user, job)
                                                                            ? "long-card"
                                                                            : "substitute-unavailable"
                                                                    }`}
                                                                >
                                                                    <Container className="image-container">
                                                                        <Card.Img
                                                                            alt=""
                                                                            variant="left"
                                                                            className="img-size"
                                                                            src={
                                                                                user.image
                                                                                    ? imgUrl + user.image
                                                                                    : DefaultAvatar
                                                                            }
                                                                        />
                                                                    </Container>
                                                                    <Col
                                                                        className="d-flex justify-content-center align-items-center">
                                                                        <Card.Body className="no-padding">
                                                                            <Card.Text
                                                                                className="mb-0 mt-0"
                                                                                style={{
                                                                                    fontWeight: "600",
                                                                                    fontSize: "1.6em",
                                                                                    color:
                                                                                        job.chosen === user.email
                                                                                            ? ""
                                                                                            : "#2e2f31",
                                                                                }}
                                                                            >
                                                                                {user.firstName} {user.lastName}
                                                                                {user?.identityVerified &&
                                                                                    <img alt="User verified icon"
                                                                                         src={VerifiedIcon}
                                                                                         className="verified-icon"/>}
                                                                            </Card.Text>
                                                                            {this.handleQualifications(
                                                                                user?.status,
                                                                                job,
                                                                                user,
                                                                                language
                                                                            )}
                                                                        </Card.Body>
                                                                    </Col>
                                                                    {job.chosen === user.email ? (
                                                                        <hr className="vertical-hr-chosen"></hr>
                                                                    ) : (
                                                                        <hr className="vertical-hr"></hr>
                                                                    )}
                                                                    <Col lg={4} md={6} sm={12}>
                                                                        <Card.Body className="custom-body">
                                                                            <Card.Text
                                                                                className=""
                                                                                style={{
                                                                                    fontSize: "1.4em",
                                                                                    color:
                                                                                        job.chosen === user.email
                                                                                            ? ""
                                                                                            : "#707070",
                                                                                    marginBottom: "0.3em",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={Location}
                                                                                    alt="location"
                                                                                    className="mr-2"
                                                                                    style={{
                                                                                        filter:
                                                                                            job.chosen === user.email
                                                                                                ? "brightness(0) invert(1)"
                                                                                                : "",
                                                                                    }}
                                                                                />{" "}
                                                                                {user?.address}
                                                                            </Card.Text>
                                                                            <Card.Text
                                                                                style={{
                                                                                    fontSize: "1.4em",
                                                                                    color:
                                                                                        job.chosen === user.email
                                                                                            ? ""
                                                                                            : "#707070",
                                                                                    marginBottom: "0.3em",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={Phone}
                                                                                    alt="phone"
                                                                                    className="mr-2"
                                                                                    style={{
                                                                                        filter:
                                                                                            job.chosen === user.email
                                                                                                ? "brightness(0) invert(1)"
                                                                                                : "",
                                                                                    }}
                                                                                />{" "}
                                                                                +358 {user?.phone}
                                                                            </Card.Text>
                                                                            <Card.Text
                                                                                className="mb-0"
                                                                                style={{
                                                                                    fontSize: "1.4em",
                                                                                    color:
                                                                                        job.chosen === user.email
                                                                                            ? ""
                                                                                            : "#707070",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={Mail}
                                                                                    alt="mail"
                                                                                    className="mr-2"
                                                                                    style={{
                                                                                        filter:
                                                                                            job.chosen === user.email
                                                                                                ? "brightness(0) invert(1)"
                                                                                                : "",
                                                                                    }}
                                                                                />{" "}
                                                                                {user?.email}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Col>
                                                                    {this.chooseBell(job, user)}
                                                                </Card>
                                                            </Col>
                                                        ) : (
                                                            ""
                                                        )
                                                    )}
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <Row className="mt-3 job-header w-auto">
                                                    <a
                                                        style={{textDecoration: "none"}}
                                                        href={`/manage/${job.uniqueId}`}
                                                    >
                            <span
                                style={{
                                    fontSize: "1.8em",
                                    fontWeight: "600",
                                    color: "#555aa1",
                                }}
                            >
                              {this.displayDates(job)}
                            </span>
                                                        <span
                                                            style={{
                                                                marginLeft: "8px",
                                                                fontWeight: "500",
                                                                fontSize: "1.6em",
                                                                color: "#555aa1",
                                                            }}
                                                        >
                              {teacher.typeOfUser !== "teacher"
                                  ? job.owner !== teacher.id
                                      ? teachers?.map((teach) => {
                                          if (job.owner === teach._id) {
                                              return `(${teach.firstName} ${teach.lastName} - ${job.reason})`;
                                          }
                                          return "";
                                      })
                                      : typeof job.regarding !== "undefined" &&
                                      job.regarding !== ""
                                          ? `  (${job.regarding} - ${job.reason})`
                                          : `  (${teacher.firstName} ${teacher.lastName} - ${job.reason})`
                                  : `(${job.reason})`}
                            </span>
                                                    </a>
                                                    <span className="view-container">
                            <OverlayTrigger
                                rootClose={true}
                                rootCloseEvent="click"
                                placement="auto"
                                trigger={["focus", "click"]}
                                delay={{show: 250, hide: 250}}
                                overlay={(props) => (
                                    <Tooltip id="views-tooltip" {...props}>
                                        <div
                                            style={{
                                                maxHeight: "60vh",
                                                overflowY: "auto",
                                                width: "200px",
                                            }}
                                        >
                                            <ul className="list-group">
                                                {job?.views?.map((view) => {
                                                    return (
                                                        <li
                                                            key={view}
                                                            className="text-left overflow-auto"
                                                            style={{listStyle: "none"}}
                                                        >
                                                            {this.getNameFromEmail(view)}
                                                            {teacher?.rememberedUsers?.includes(
                                                                view
                                                            ) ? (
                                                                <img
                                                                    alt="bookmarked icon"
                                                                    src={BookMarked}
                                                                    width={12}
                                                                    className="ml-2"
                                                                />
                                                            ) : null}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </Tooltip>
                                )}
                            >
                              <img
                                  alt=""
                                  className="desktop-view-svg pe-cursor"
                                  src={Views}
                              />
                            </OverlayTrigger>
                            <p className="view-counter">{job.views.length}</p>
                          </span>
                                                    <Link
                                                        to={{
                                                            pathname: `/manage/${job.uniqueId}`,
                                                            state: {
                                                                fromEditIcon: true,
                                                            },
                                                        }}
                                                    >
                                                        <img
                                                            alt=""
                                                            className="edit-svg mr-4"
                                                            style={{marginLeft: "3.4em"}}
                                                            src={EditIcon}
                                                        />
                                                    </Link>
                                                </Row>
                                                <Row>
                                                    {applicants.map((user, index) =>
                                                        this.checkIfApplied(job, user.email) ? (
                                                            <Col  lg={4} md={6} sm={12} key={index} className="mb-3 mt-2">
                                                                {/* <StarRating /> */}
                                                                <Card
                                                                    onClick={(event) => {
                                                                        if (this.isAvailable(user, job)) {
                                                                            this.handleOpenChange(user, job.uniqueId);
                                                                        }
                                                                    }}
                                                                    className={`${
                                                                        job.chosen === user.email && "chosen"
                                                                    }  ${
                                                                        this.isAvailable(user, job)
                                                                            ? "long-card"
                                                                            : "substitute-unavailable"
                                                                    }`}
                                                                >
                                                                    <Container className="image-container">
                                                                        <Card.Img
                                                                            alt=""
                                                                            variant="left"
                                                                            className="img-size"
                                                                            src={
                                                                                user.image
                                                                                    ? imgUrl + user.image
                                                                                    : DefaultAvatar
                                                                            }
                                                                        />
                                                                    </Container>
                                                                    <Col
                                                                        className="d-flex justify-content-center align-items-center"
                                                                    style={{
                                                                        width: "0px"
                                                                    }}>
                                                                        <Card.Body className="no-padding truncate-90">
                                                                            <Card.Text
                                                                                className="mb-0 mt-0"
                                                                                style={{
                                                                                    fontWeight: "600",
                                                                                    fontSize: "1.6em",
                                                                                    color:
                                                                                        job.chosen === user.email
                                                                                            ? ""
                                                                                            : "#2e2f31",
                                                                                }}
                                                                            >
                                                                                {user.firstName} {user.lastName}
                                                                                {user?.identityVerified &&
                                                                                    <img alt="User verified icon"
                                                                                         src={VerifiedIcon}
                                                                                         className="verified-icon"/>}
                                                                            </Card.Text>
                                                                            {this.handleQualifications(
                                                                                user?.status,
                                                                                job,
                                                                                user,
                                                                                language
                                                                            )}
                                                                        </Card.Body>
                                                                    </Col>
                                                                    {this.chooseBell(job, user)}
                                                                </Card>
                                                            </Col>
                                                        ) : (
                                                            ""
                                                        )
                                                    )}
                                                </Row>
                                            </>
                                        )}
                                        <Row>
                                            <hr className="anslagstavlan-hr"></hr>
                                        </Row>
                                    </Container>
                                ))
                            ) : (
                                <>
                                    <TeacherMobileLayout
                                        icon={Applied}
                                        text={language.nav_bulletin}
                                        jobs={tempJobs}
                                        displayDates={this.displayMobileDates}
                                        user={teacher}
                                        chooseBell={this.chooseBell}
                                        applicants={applicants}
                                        viewsTooltip={this.viewsTooltip}
                                        teachers={teachers}
                                        checkIfApplied={this.checkIfApplied}
                                        choseClass={this.choseClass}
                                        handleOpenChange={this.handleOpenChange}
                                        renderCard={true}
                                        language={language}
                                        handleQualifications={this.handleQualifications}
                                        preschool={preschool}
                                        viewUsers={this.state.viewUsers}
                                        rememberedUsers={this.props.auth?.teacher?.rememberedUsers}
                                    />
                                </>
                            )
                        ) : (
                            <div className="spin-wrapper">
                                <Spinner size="8x" spinning={"spinning"}/>
                            </div>
                        )}
                    </Container>
                </div>
            </div>
        );
    }
}

Anslagstavlan.propTypes = {
    auth: PropTypes.object.isRequired,
    logoutTeacher: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {logoutTeacher})(
    withRouter(Anslagstavlan)
);
